import React from 'react';
import Layout from '../../../components/Layout';
import { Container, Row, Column } from '../../../components/Grid';
import Section from '../../../components/Section';
import { Link } from 'gatsby';
import Image from '../../../components/Image';

const DevinWillis = () => (
    <Layout title="Alumni Spotlight - Devin Willis" className="alumni-center bios">
    {/* Alumni Profile */}
    <Section className="alumni">
        <Container>
        {/* Breadcrumbs */}
        <ul className="breadcrumbs no-hero">
          <li className="breadcrumb">
            <Link to="../../overview">Overview</Link>
          </li>
          <li className="breadcrumb">
            <Link to="../../alumni-spotlight">Alumni Spotlight</Link>
          </li>
          <li className="breadcrumb active">Devin Willis</li>
        </ul>
        <Row>
        <Column size={7} offset={1} className="alumni__profile-left pr-2">
            <h2 className="alumni__profile-greeting">Meet Alumni:</h2>
            <h1 className="alumni__profile-title">Devin Willis</h1>
            <h3 className="alumni__profile-type">
                2017 3M Young Scientist Challenge Winner
            </h3>
            <h4 className="alumni__profile-project">
              Devin’s 3M Young Scientist Challenge project was a device, SlideMap, designed to expedite and enhance the precision of cancer diagnosis. He is currently studying electrical engineering at the University of Florida.
            </h4>
            <h2 className="alumni__profile-question">
                Could you elaborate on your 3M Young Scientist Challenge Innovation Project? Have you continued your involvement with this innovation subsequent to its presentation during the final event at 3M?
            </h2>
            <p>
                For the 3M Young Scientist Challenge, I designed a device known as SlideMap with the primary objective to expedite and enhance the precision of cancer diagnosis. The device incorporated affordable 3D printer technology and do-it-yourself microscope optics to capture high-resolution images of tumor slides. By applying a deep learning algorithm I had trained, the device could generate a heatmap that pinpointed areas with a high likelihood of containing cancerous cells. The distribution and concentration of these hot spots on the heatmap could potentially steer the process of cancer diagnosis. For a few years after the challenge, I continued to refine the device, incorporating high-quality materials and leveraging new engineering insights.
            </p>
            <h2 className="alumni__profile-question">
                What was your most cherished memory from the 3M Young Scientist Challenge?
            </h2>
            <p>
                The memory I treasure most from the 3M Young Scientist Challenge was the opportunity to meet and engage with all the other finalists. Learning about their innovative ideas spanning various fields of science and engineering was truly invigorating.
            </p>
            <h2 className="alumni__profile-question">
              You had initially aspired to become a bioengineer when you participated in the Challenge. Did you eventually follow this path? Could you share more about your journey?
            </h2>
            <p>
              Post the Challenge, I completed most of the credits required for an honors degree in neuroscience through the Max Planck program at Florida Atlantic University. After that, I transitioned to the University of Florida, where I am currently pursuing an electrical engineering degree. My aspiration is to amalgamate my knowledge from neuroscience and electrical engineering to develop more advanced brain-machine interfaces. Concurrently, I have been steadfast in pursuing personal engineering projects.
            </p>
            <h2 className="alumni__profile-question">
              Recently, you've been collaborating with Hannah Herbst, another former finalist of the 3M Young Scientist Challenge. Could you provide more details about this project? What has the experience of working with a fellow 3M YSC alumnus been like?
            </h2>
            <p>
              Hannah and I have known each other since middle school; we shared experiences competing in various robotics teams. We both share an unwavering passion for problem-solving and our skills synergize well, making us an effective team. Currently, we are developing an automated tourniquet that can be easily used by anyone and guarantees proper application every time. Hannah's focus on product design and business, combined with my engineering expertise, has driven significant progress since the inception of our project.
            </p>
            <h2 className="alumni__profile-question">
              How has the 3M Young Scientist Challenge contributed or led you to your current line of work?
            </h2>
            <p>
              The 3M Young Scientist Challenge was a major catalyst in inspiring me to remain steadfast in my engineering and science pursuits. It instilled in me the determination to keep innovating and working toward the next breakthrough. Over time, I've strived to broaden my knowledge across various scientific fields, with the anticipation that it will equip me to solve critical issues.
                        </p>
          </Column>
          <Column size={4}>
            <div className="global__profile-box box-shadow">
              <div className="global__profile-image">
                <Image filename="Willis-Devin-2024-Alumni-Quote.png" />
              </div>
              <div className="global__profile-content">
                <h4 className="quote">
                  “The 3M Young Scientist Challenge was a major catalyst in inspiring me to remain steadfast in my engineering and science pursuits.”
                </h4>
                <h3>Devin Willis</h3>
                <p>2017 Finalist</p>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Related Alumni Profiles */}
    <Section className="related-profiles">
      <Container>
        <Row>
          <Column fullwidth>
            <h2>Related Alumni</h2>
          </Column>
        </Row>
                <Row>
                    <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="Crouchley-Caroline-2024-Alumni-Card.png" />
              </div>
              <div className="profile-card__content">
                <span>2019 Finalist</span>
                <h3>Caroline Crouchley</h3>
                <p>
                  Since participating in the 2019 Challenge, Caroline has been researching to investigate new drip irrigation methods to grow plants more efficiently and conserve water. Caroline is excited to be part of the Alumni Network!
                </p>
                <Link to="caroline-crouchley" className="text-20 text-bold">
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
                    <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="Herbst-Hannah-2024-Alumni-Card.png" />
              </div>
              <div className="profile-card__content">
                <span>2015 Finalist</span>
                <h3>Hannah Herbst</h3>
                <p>
                  After the 3M Young Scientist Challenge, Hannah continued to work on her project, BEACON, an ocean energy collection and conversion device. Hannah attended Florida Atlantic University and Stanford University, and is now an entrepreneur working on some incredible projects!
                </p>
                <Link to="hannah-herbst" className="text-20 text-bold">
                  Learn More {'>'}
                </Link>
              </div>
            </div>
                    </Column>
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="Katie-Lampo-Alumni-Card.jpg" />
              </div>
              <div className="profile-card__content">
                <span>2017 Finalist</span>
                <h3>Kathryn Lampo</h3>
                <p>
                  Kathryn is currently a sophomore at Columbia University
                  pursuing a B.S. in Mechanical Engineering with a minor in
                  Applied Physics. She’s also interested in aerospace
                  engineering!
                </p>
                <Link to="../katie-lampo" className="text-20 text-bold">
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>

        </Row>
      </Container>
    </Section>
  </Layout>
);

export default DevinWillis;
